import debounce from 'debounce';

export default function useScrollable({
  scrollableRef,
  scrollableContainerRef,
}: {
  scrollableRef: Ref<HTMLElement | null>;
  scrollableContainerRef: Ref<HTMLElement | null>;
}) {
  /********************
   * REFS & VARS       *
   ********************/
  const resizeObserver = ref<ResizeObserver | null>(null);
  const scrollabaleWidth = ref(0);
  const scrollableContainerWidth = ref(0);
  const showLeftGradient = ref(false);
  const showRightGradient = ref(false);

  /********************
   * FUNCTIONS         *
   ********************/
  function isMounted() {
    if (!scrollableRef.value || !scrollableContainerRef?.value) {
      return;
    }

    if ('ResizeObserver' in window) {
      resizeObserver.value = new ResizeObserver(debounce(() => handleGradients(), 150));
      resizeObserver.value.observe(scrollableContainerRef.value);
    }
    scrollableRef.value.onscroll = debounce(handleGradients, 150);
    handleGradients();
  }

  function handleGradients() {
    scrollableContainerWidth.value = scrollableContainerRef.value?.clientWidth;
    scrollabaleWidth.value = scrollableRef.value?.scrollWidth;

    if (scrollableContainerWidth.value >= scrollabaleWidth.value) {
      return;
    }

    const scrollLeft = scrollableRef.value?.scrollLeft;

    showLeftGradient.value = (scrollLeft || 0) > 0;
    showRightGradient.value
      = scrollabaleWidth.value - scrollableContainerWidth.value - (Math.floor(scrollLeft) || 0) !== 0;
  }

  /********************
   * HOOKS             *
   ********************/
  onMounted(() => {
    if (!scrollableRef.value || !scrollableContainerRef.value) {
      nextTick(() => {
        isMounted();
      });
      return;
    }
    isMounted();
  });

  onBeforeUnmount(() => {
    if (scrollableRef.value) {
      scrollableRef.value.onscroll = null;
    }

    resizeObserver.value?.disconnect();
  });

  return {
    showLeftGradient,
    showRightGradient,
  };
}
